let _apikey: string = ''

export const StorageSetup = (key: string) => {
	console.log('setting up storage', key)
	_apikey = key
}

const name = (key: string): string => {
	if (_apikey === '') throw new Error('Storage is not ready!')
	return `${ _apikey }___${ key }`
}

export const Storage = {
	APIKey: () => _apikey,

	Get: (key: string): string | null => {
		return localStorage.getItem(name(key))
	},

	Set: (key: string, value: string) => {
		return localStorage.setItem(name(key), value)
	},

	Remove: (key: string) => {
		return localStorage.removeItem(name(key))
	},

	GetFromCookie: (name: string): string | undefined => {
		const cookie = document.cookie.split(';').filter(function(token) {
			const left = token.split('=')[0].replace(/ /g, '')
			return left === name
		})[0]
		if (!cookie) return undefined
		
		let parts = cookie.split('=')
		let value = cookie.split('=')[1]
		if (parts.length > 2) {
			parts.splice(0, 1)
			value = parts.join('')
		}
		if (value === 'undefined') return undefined
		return value
	}
}

export const testLocalstorage = (): boolean => {
	try {
		localStorage.setItem('test', 'test')
		return true
	} catch (error) {
		return false
	}
}