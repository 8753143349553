import Vue from 'vue'
import Vuex from 'vuex'
import { createDirectStore } from 'direct-vuex'

import SettingsModule from './module.settings'

Vue.use(Vuex)

const pStore = {
	modules: {
		settings: SettingsModule
	},
}

const { rootActionContext, moduleActionContext, store } = createDirectStore(pStore) 

export default store

export { rootActionContext, moduleActionContext }

export type AppStore = typeof store
declare module 'vuex' {
	interface Store<S> {
		direct: AppStore
	}
}


