const _isMobile = {
	Android: function():boolean {
		return navigator.userAgent.match(/Android/i) !== null;
	},
	BlackBerry: function():boolean {
		return navigator.userAgent.match(/BlackBerry/i) !== null;
	},
	iOS: function():boolean {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
	},
	Opera: function():boolean {
		return navigator.userAgent.match(/Opera Mini/i) !== null;
	},
	Windows: function():boolean {
		return navigator.userAgent.match(/IEMobile/i) !== null;
	},
	any: function():boolean {
		return (
			this.Android() ||
				this.BlackBerry() ||
				this.iOS() ||
				this.Opera() ||
				this.Windows()
		);
	}
}


export const isMobile = (): boolean => {
	return _isMobile.any()
}