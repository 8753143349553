











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

var openCalloutInterval: any

@Component({
	components: {
		PulseLoader
	}
})
export default class Callout extends Vue {
	@Prop({ default: false }) chatActived!: boolean
	@Prop({ default: {} }) data!: any

	active = false

	get styleCallout(): IstyleCallout {
		return {
			bottom: this.data.marginBottom + 80 +	'px',
			right: this.data.marginRight + 'px',
			color: this.data.textColor,
			'--color': this.data.textColor,
			border: 'solid 1.5px' + this.data.borderColor,
			backgroundColor: this.data.backgroundColor,
			zIndex: this.data.zIndex,
			'--fontFamily': this.data.fontStyle + ', sans-serif'
		}
	}

	openCallout() {
		let delay = this.active ? 0 : this.data.enterDelay
		
		openCalloutInterval = setTimeout(() => {
			this.toggleCallout(true)
		}, delay);
	}

	toggleCallout(val: boolean) {
		this.active = val
	}

	@Watch('chatActived')
	chatActivedChanged(newval: boolean) {
		clearTimeout(openCalloutInterval)
		var accountKey = localStorage.getItem('ak')
		var sessionAStarted = !!localStorage.getItem('vouch___password')
		var sessionBStarted = !!localStorage.getItem(accountKey+'___username')
		console.log('callout pop requirement',accountKey, sessionBStarted)
		if (!newval) {
			if (sessionAStarted || sessionBStarted) {
				this.toggleCallout(false)
			} else {
				this.toggleCallout(true)
			}
		} else {
			this.toggleCallout(false)
		}
	}

	mounted() {
		this.openCallout()
	}
}

interface IstyleCallout {
	bottom: string,
	right: string,
	color: string,
	border: string,
	backgroundColor: string,
	zIndex: string,
	'--fontFamily': string,
	'--color': string,
}
