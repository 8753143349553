let _eventHandler: string | undefined = undefined


export interface IEventPayload {
	type: string,
	data: any
}
type WindowHandler = (payload: IEventPayload) => void

export const SendEventToHandler = (payload: IEventPayload) => {
	try {
		if (!_eventHandler) return console.log('wrapper no event handler', payload.type)
		const _handler = (window as { [key: string]: any })[_eventHandler] as WindowHandler
		_handler(payload)
	} catch (e) {
		console.log('wrapper event handler failure', e)
	}
}

export const SetupEventHandler = (name: string) => {
	console.log('wrapper setting up event handler', name)
	_eventHandler = name
}