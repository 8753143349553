import Axios, { AxiosResponse } from 'axios'

export enum API_METHOD {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE'
}

export const APICall = async <R = any>(method: API_METHOD, url: string, headers: any, data: any = undefined, configs: any = {}): Promise<R> => {
	let ax: AxiosResponse<R>
	try {
		ax = await Axios.request<R>({
			method,
			url,
			headers,
			data,
			...configs
		})
		return ax.data
	} catch (e) {

		console.log(e.response)

		if (e.response && e.response.status === 403 || e.response.status === 401) {
			// store.dispatch.user.doLogout()
		}

		throw e
	}
	
}

export const TokenHeader = (token: string): any => ({
	'token': token,
})

export const JSONHeader = ({
	'Content-Type': 'application/json'
})

export const APIConfigWithCredential = { withCredentials: true }