












import { Component, Vue, Watch } from 'vue-property-decorator'
import posed from 'vue-pose'

@Component({
	components: {
		ImageButton: posed.div({
			show: {
				opacity: 1
			},
			hide: {
				opacity: 0
			}
		}),
		CloseImageButton: posed.div({
			muncul: {
				opacity: 1
			},
			ilang: {
				opacity: 0
			}
		})
	}
})
export default class ChatButton extends Vue {

	poseOpen = 'show'
	poseClose = 'ilang'
	btnBgColor = '#fff'

	@Watch('state')
	stateChanged(newval: number) {
		console.log('state.changed', newval)
		if (newval === 1) {
			this.poseOpen = 'hide'
			this.poseClose = 'muncul'
		} else {
			this.poseOpen = 'show'
			this.poseClose = 'ilang'
		}
	}

	get state(): number {
		let state = this.$store.direct.state.settings.chatButtonState
		return state
	}

	mounted() {
		// this.poseClose = 'hide' 
		console.log('chatbutton.mounted', 'state', this.state)
		setTimeout(() => {
			// this.stateChanged(0, 0)
			// this.clicked()
		}, 3000)
	}

	shouldShow(targetState: number) {
		let state = this.$store.direct.state.settings.chatButtonState
		return targetState === state
	}
	
	clicked() {
		this.$emit('click')

		// let state = this.state
		// this.$store.direct.commit.settings.setButtonState( state === 0 ? 1 : 0 )
	}

}

