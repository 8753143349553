import { moduleActionContext } from '.'
import { APICall, API_METHOD } from './apicall'
import { ITabItem } from './types'
import Axios from 'axios'

const _API_URL = `${ process.env.VUE_APP_API_HOST }`
const API_URL = (path: string) => `${_API_URL}/${path}`

interface IMigration {
	username?: string,
	password?: string,
	enabled?: boolean
}

interface IDomainWhitelist {
	enabled: boolean,
	value: string
}

interface ISetting {
	// App Bar
	title?: string,
	headerBgColor?: string,
	headerTextColor?: string,
	xButtonColor?: string,

	// message bubble right
	rightBubbleBgColor?: string,
	rightBubbleColor?: string,

	// message bubble left
	leftBubbleBgColor?: string,
	leftBubbleColor?: string

	// panel
	backgroundColorChat?: string

	// text input
	inputTextBackgroundColor?: string,
	inputTextColor?: string,

	// send button
	sendIconColor?: string,
	sendButtonColor?: string,
	sendButtonType?: string,
	sendButtonText?: string
		
	attachmentButtonColor?: string,
	attachmentIconColor?: string,
		
	footerTextColor?: string,

	integrationList?: ITabItem[]

	// behaviour
	behaviour: number,
	mobileBehaviour: number,
	delay: number,
	mobileDelay: number,
	hideCloseButton: boolean, // hide widget button when panel appear
	openImmediately: boolean,
	domainWhiteList: IDomainWhitelist[],
	domainWhitelistVersion: string,
	whitelisted: boolean,

	// positions & size
	maxHeight: number,
	maxWidth: number,
	chatPanelTop: number,
	chatPanelBottom: number,
	chatPanelRight: number,
	widgetButtonSize: number,

	chatButtonBottom: number,
	chatButtonRight: number

	// z-index
	zIndexChatButton: number,
	zIndexChatWindow: number

	// toggles
	forceFullScreen: boolean

	// others
	openIconURL: string,
	closeIconURL: string,
	btnBgColor: string,
	btnCloseBgColor: string,

	callout: ICallout,
}

interface ICallout {
	enabled: boolean,
	text: string,
	backgroundColor: string,
	textColor: string,
	borderColor: string,
	shape: string,
	enterAnimation: string,
	exitAnimation: string,
	enterDelay: number,
	marginRight: number,
	marginBottom: number
}

interface ISimpleBackground {
	backgroundColor: string
}

interface ISimpleTextColor {
	color: string
}

interface ITextSize {
	fontSize: number
}

interface IStoreSettings {
	setting?: ISetting,
	style: {
		appBar: ISimpleBackground & ISimpleTextColor,
		appBarX: ISimpleTextColor,
		msgLeftBubble: ISimpleBackground & ISimpleTextColor,
		msgRightBubble: ISimpleBackground & ISimpleTextColor,
		panel: ISimpleBackground,
		textInput: ISimpleBackground & ISimpleTextColor,
		sendButton: ISimpleBackground & ISimpleTextColor,
		attachmentButton: ISimpleBackground & ISimpleTextColor,
		footer: ISimpleTextColor,
		openIcon: { [key: string]: string },
		closeIcon: { [key: string]: string | number },
		openButton: { [key: string]: string | number },
		closeButton: { [key: string]: string | number },
		activator: {
			zIndex: number,
			right: number,
			width: number,
			height: number,
			borderRadius: number,
		}
	},
	chatframe: string
	chatButtonState: number,
	migration?: IMigration,
	callout: ICallout
}

interface IPojoSetting {
	data: ISetting
}

const _unset = 'unset'

const _defaultStyle = {
	backgroundColorUnset: { backgroundColor: _unset },
	colorUnset: { color: _unset }
}

const SettingModule = {
	namespaced: true,

	state: {
		chatframe: `data:text/html;charset=utf-8,${ encodeURI('<html><body>Something went wrong.</body></html>') }`,
		setting: {

		},
		style: {
			appBar: {
				..._defaultStyle.backgroundColorUnset,
				..._defaultStyle.colorUnset
			},
			appBarX: {
				..._defaultStyle.colorUnset
			},
			msgLeftBubble: {
				..._defaultStyle.backgroundColorUnset,
				..._defaultStyle.colorUnset
			},
			msgRightBubble: {
				..._defaultStyle.backgroundColorUnset,
				..._defaultStyle.colorUnset
			},
			panel: {
				..._defaultStyle.backgroundColorUnset
			},
			textInput: {
				..._defaultStyle.backgroundColorUnset,
				..._defaultStyle.colorUnset
			},
			sendButton: {
				..._defaultStyle.backgroundColorUnset,
				..._defaultStyle.colorUnset
			},
			attachmentButton: {
				..._defaultStyle.backgroundColorUnset,
				..._defaultStyle.colorUnset
			},
			footer: {
				..._defaultStyle.colorUnset
			},
			activator: {}
		},
		chatButtonState: 0
	} as IStoreSettings,

	mutations: {

		setMigrations(state: IStoreSettings, migration: IMigration) {
			state.migration = migration
		},

		setButtonState(state: IStoreSettings, btnState: number) {
			state.chatButtonState = btnState
		},

		setSettings(state: IStoreSettings, settings: ISetting) {
			state.setting = settings
			state.style.appBar = {
				backgroundColor: settings.headerBgColor!,
				color: settings.headerTextColor!
			}
			state.style.appBarX = {
				color: settings.xButtonColor!
			}
			state.style.msgRightBubble = {
				backgroundColor: settings.rightBubbleBgColor!,
				color: settings.rightBubbleColor!
			}
			state.style.msgLeftBubble = {
				backgroundColor: settings.leftBubbleBgColor!,
				color: settings.leftBubbleColor!
			}
			state.style.panel = {
				backgroundColor: settings.backgroundColorChat!
			}
			state.style.textInput = {
				backgroundColor: settings.inputTextBackgroundColor!,
				color: settings.inputTextColor!
			}
			state.style.sendButton = {
				backgroundColor: settings.sendButtonColor!,
				color: settings.sendIconColor!
			}
			state.style.attachmentButton = {
				backgroundColor: settings.attachmentButtonColor!,
				color: settings.attachmentIconColor!
			}
			state.style.footer = {
				color: settings.footerTextColor!
			}

			state.style.activator = {
				zIndex: settings.zIndexChatButton,
				right: settings.chatButtonRight,
				width: settings.widgetButtonSize,
				height: settings.widgetButtonSize,
				borderRadius: settings.widgetButtonSize/2,
			}

			state.style.openIcon = {
				background: `url(${ settings.openIconURL })`,
				backgroundPosition: 'center',
				backgroundSize: 'contain'
			}

			state.style.closeIcon = {
				background: `url(${ settings.closeIconURL })`,
				backgroundPosition: 'center',
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
				// opacity: 0
			}
			state.style.openButton = {
				backgroundColor: settings.btnBgColor,
				opacity: 1
			}
			state.style.closeButton = {
				backgroundColor: settings.btnCloseBgColor,
				opacity: 0
			}
			state.callout = settings.callout
			/** 
						 * Test Data
						*/
			// state.callout = {
			//		 enabled: true,
			//		 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
			//		 backgroundColor: "#e67e22",
			//		 textColor: "#ecf0f1",
			//		 borderColor: "#d35400",
			//		 shape: "callout",
			//		 enterAnimation: "fade",
			//		 exitAnimation: "out",
			//		 enterDelay: 1000,
			//		 marginRight: 20,
			//		 marginBottom: 120
			// }

			// state.setting.sendButtonType = 'text'
			// state.setting.sendButtonText = 'OK'

			let _allowed = false
			if (settings.domainWhitelistVersion === 'v2') {
				const domain = settings.domainWhiteList.find(domain => {
					if (domain.enabled !== true) return false
			
					const _url = new URL(domain.value)
					const dorigin = _url.origin
					return dorigin === location.origin       
				})
				_allowed = !!domain
				// const __url = `${ location.origin }${ location.pathname }`
				// console.log('--setings', 'domain_check', __url)
				// for (let i = 0; i < settings.domainWhiteList.length; i++) {
				// 	const d = settings.domainWhiteList[i]

				// 	if (!d.enabled) continue
				// 	let _matched = false
				// 	if (d.value.indexOf('*') === -1) {
				// 		_matched = d.value === __url
				// 		console.log('--settings', 'domain_check', 'test', d, _matched)
				// 	} else {
				// 		const __regex = new RegExp(d.value.replace(/\*/g, '.*'))
				// 		_matched = __regex.test(__url)
				// 		console.log('--settings', 'domain_check', 'test', d, __regex, _matched)
				// 	}
					
				// 	_allowed = _matched
				// 	if (_allowed) {
				// 		console.log('--settings', 'domain_check', 'allowed', _allowed, d)
				// 		break
				// 	}
				// }

				// console.log('--settings', 'domain_check', '--result', _allowed)				
			} else {
				_allowed = true
			}

			state.setting.whitelisted = _allowed
						
		},

	},

	actions: {
		async loadSettings(ctx: any) {
			let { commit } = moduleContext(ctx)
			let name = localStorage.getItem('profile')
			if (!name || name === '') name = 'Default'
			console.log('loading setting..', name)
			let path = (name === 'Default') ? 'stylev2' : 'stylev2/' + name
			let result: IPojoSetting = await APICall<IPojoSetting>(API_METHOD.GET, API_URL(path), {
				'api-key': localStorage.getItem('ak')
			}, {})
			commit.setSettings(result.data)
		},

		headerStyle(ctx: any): any {
			let { state } = moduleContext(ctx)
			let style = {
				'background-color': state.setting!.headerBgColor,
				color: state.setting!.headerTextColor
			}
			return style
		},

		xButtonStyle(ctx: any): any {
			let { state } = moduleContext(ctx)
			return {
				color: state.setting!.xButtonColor
			}
		},

		activatorStyle(ctx: any): any {
			let { state } = moduleContext(ctx)
			return {
				zIndex: state.setting!.zIndexChatButton,
				right: state.setting!.chatButtonRight,
				width: state.setting!.widgetButtonSize,
				height: state.setting!.widgetButtonSize,
				borderRadius: state.setting!.widgetButtonSize/2,
			}
		},

		async loadChatFrame() {
			let html = await Axios.get(process.env.VUE_APP_TRUSTED_WIDGET)
			console.log('html', html.data)
		}
	}
} as const

export default SettingModule
const moduleContext = (context: any) => moduleActionContext(context, SettingModule)