














































import { Component, Vue } from 'vue-property-decorator'
import posed from 'vue-pose'

// import { isMobile } from 'mobile-device-detect'
import { isMobile } from './utils/util.ismobile'

import waitProm from './utils/util.waitprom'
import { IEventPayload, SendEventToHandler, SetupEventHandler } from './utils/util.eventhandler'

let merchant: string | null = null
let stb_storage: string | null = null
let stb_merchant: string | null = null
let appDOM = document.getElementById('app')

let v1_username: string | undefined = ''
let v1_password: string | undefined = ''

const stb_mode = process.env.VUE_APP_STB_MODE === '1'

if (!appDOM) {
	appDOM = document.getElementById('vouch-chat')
}

if (!appDOM) throw new Error('no app')

let apikey: string | null = null
let DOMUserAttribute: {[key:string]:string} = {}
let vcpid: string | null = null
let channel: string | null = null
if (appDOM) {
	merchant = appDOM.getAttribute('merchant')
	apikey = appDOM.getAttribute('apikey')
	stb_storage = appDOM.getAttribute('storage') || 'cookie'
	console.log('App.appDOM config', stb_storage, merchant, apikey, stb_mode)

	stb_merchant = merchant
	if (stb_mode) {
		if (stb_storage === 'localstorage') {
			let _v1_username = localStorage.getItem(`${ stb_merchant }_vouch-widget-userId`)
			let _v1_password = localStorage.getItem(`${ stb_merchant }_vouch-widget-password`)
			if (_v1_username) v1_username = _v1_username
			if (_v1_password) v1_password = _v1_password
		} else {
			v1_username = Storage.GetFromCookie(`${ stb_merchant }_vouch-widget-userId`)
			v1_password = Storage.GetFromCookie(`${ stb_merchant }_vouch-widget-password`)
		}
	}

	let migrationMode = appDOM.getAttribute('migration-mode') === '1'
	if (!migrationMode) {
		merchant = null
	}

	let domAttributes = appDOM.getAttributeNames().filter(attr => attr.indexOf('user-attribute-') === 0)
	for (let i = 0; i < domAttributes.length; i++) {
		let attr = domAttributes[i]
		let val = appDOM.getAttribute(attr)
		if (val) DOMUserAttribute[attr] = val
	}

	const _vc_pid = appDOM.getAttribute('vc-pid')
	if (_vc_pid) {
		vcpid = _vc_pid
	}

	const _channel = appDOM.getAttribute('channel')
	if (_channel) {
		channel = _channel
	}

	const eventHandler = appDOM.getAttribute('event-handler')
	if (eventHandler) {
		SetupEventHandler(eventHandler)
	}
}
// components
import ChatPanel from './components/ChatPanel.vue'
import ChatButton from './components/ChatButton.vue'
import Callout from './components/Callout.vue'
import VueCookies from 'vue-cookies'

console.log('setup', apikey, merchant, channel, stb_merchant, stb_storage, stb_mode)

Vue.use(VueCookies)
// Vue.use(VueSkeletonLoading) // conflict with "https://lkyspp.nus.edu.sg/" website (display messed up), need to remove to put our widget there.

import { Storage, StorageSetup } from './utils/util.storage'
StorageSetup(apikey || 'vouch')

if (stb_mode) {
	const current_username = Storage.Get('username')
	if (!current_username && v1_username && v1_password) {
		// migrate from stb v1
		Storage.Set('username', v1_username)
		Storage.Set('password', v1_password)
		console.log('migrated from stb')
	} else {
		console.log('not migrated from stb', current_username)
	}
}

const _migration: { username?: string, password?: string, enabled?: boolean } = {}
if (merchant) {
	
	let username: string | null = null
	let password: string | null = null

	let wwapp_cred = localStorage.getItem('persist:user')
	if (wwapp_cred) {
		console.log('migrating from', merchant, 'wwapp')
		try {
			let parsed_cred: { pid: string, password: string } = JSON.parse(wwapp_cred)
			username = parsed_cred.pid.replace(/"/g, '')
			password = parsed_cred.password.replace(/"/g, '')
		} catch (e) {
			console.log('failed to parse wwapp cred', wwapp_cred)
		}
	} else {
		console.log('migrating from', merchant, 'old widget')
		username = Vue.$cookies.get(`${ merchant }_vouch-customerId`)
		password = Vue.$cookies.get(`${ merchant }_vouch-conversationSecret`)
	}

	let greeted: { code: number } = Vue.$cookies.get(`${ merchant }_api-greet-called`)
	if (greeted && typeof greeted === 'string') {
		try {
			greeted = JSON.parse(greeted)
		} catch (e) {
			console.log('cannot parse greeted', greeted, e)
		}
	}

	console.log('migrate cred', greeted, username, password)
	if (greeted && greeted.code === 200) {
		_migration.username = username!
		_migration.password = password!
		_migration.enabled = true

		console.log('migrate cookie', username, password, greeted)
	}
}

const _anim_duration = 150

interface ISmpCoord {
	top: number,
	left: number,
	right: number,
	bottom: number,
	height: number,
	width: number,
	borderRadius: number
}

interface IFrameMessage {
	action: string,
	payload: any
}

@Component({
	components: {
		ChatPanel,
		ChatButton,
		Callout,
		Activator: posed.div({
			show: {
				applyAtStart: { display: 'block' },
				bottom: ({ bottom }: ISmpCoord) => bottom,
				opacity: 1,
				transition: {
					type: 'spring',
					duration: _anim_duration
				}
			},
			hide: {
				bottom: ({ height, bottom }: ISmpCoord) => -(height + bottom),
				right: ({ right }: ISmpCoord) => right,
				width: ({ width }: ISmpCoord) => width,
				height: ({ height }: ISmpCoord) => height,
				borderRadius: ({ borderRadius }: ISmpCoord) => borderRadius,
				transition: {
					type: 'spring',
					duration: _anim_duration
				}
			},
			hide2: {
				applyAtEnd: { display: 'none' },
				opacity: 0
			}
		}),
		Panelp: posed.div({
			show: {
				applyAtStart: { display: 'block' },
				bottom: ({ bottom }: ISmpCoord) => bottom,
				right: ({ right }: ISmpCoord) => right,
				opacity: 1,
				transition: {
					type: 'spring',
					duration: _anim_duration
				}
			},
			hide: {
				applyAtEnd: { display: 'none' },
				bottom: ({ bottom }: ISmpCoord) => bottom - 40,
				right: ({ right }: ISmpCoord) => right,
				opacity: 0,
				transition: {
					duration: _anim_duration
				}
			}
		})
	}
})
export default class App extends Vue {

	created() {

	}

	show = false
	activatorPose = 'hide'
	panelPose = 'hide'

	get shouldFullscreen(): boolean {
		let forceFullScreen = this.$store.direct.state.settings.setting!.forceFullScreen === true
		return isMobile() || forceFullScreen
	}

	mounted() {
		this.registerFrameMessageHandlers()
		console.log('wrapper -app.mounted', process.env.VUE_APP_VERSION, _migration.enabled)

		if (channel) {
			Storage.Set('channel', channel)
		}

		if (_migration.enabled) {
			let migrated = localStorage.getItem(`${ merchant }_migrated`) === '1'
			if (!migrated) this.$store.direct.commit.settings.setMigrations(_migration)
		}
		this.run()
	}

	// updated(){
	// 	const setting = this.$store.direct.state.settings.setting
	// 	// console.log('$store.direct.state' , this.$store.direct.state.settings)
	// 	if( this.$store.direct.state.settings.setting && document){
	// 		const styleWidget = document.createElement('style')
	// 		styleWidget.type = 'text/css'
	// 		styleWidget.innerHTML = `
	// 		@media only screen and (min-width: ${ this.$store.direct.state.settings.setting.maxWidth	}px) {
	// 			.vc-widget-panel {
	// 				width: ${	this.$store.direct.state.settings.setting.maxWidth }px;
	// 			}
	// 		}
	// 		@media only screen and (min-height: ${ this.$store.direct.state.settings.setting.maxHeight	}px) {
	// 			.vc-widget-panel {
	// 				height: ${	this.$store.direct.state.settings.setting.maxHeight }px;
	// 			}
	// 		}
	// 		`
	// 		const app = document.getElementById("app")
	// 		app && app.append(styleWidget)
	// 	}
	// }

	registerFrameMessageHandlers() {
		window.addEventListener('message', this.onIframeMessage.bind(this), { capture: true, passive: true })
	}

	onIframeMessage(ev: MessageEvent) {
		if (ev.data && ev.data.source) {
			return
		}

		console.log('widget.onmessage', ev.origin, ev.data, process.env.VUE_APP_TRUSTED_WIDGET_ORIGIN)
		if (ev.origin !== process.env.VUE_APP_TRUSTED_WIDGET_ORIGIN) return

		let msg: IFrameMessage = ev.data

		if (msg.action === 'close_panel') {
			console.log('closing..')
			this.activatorClicked()
			this.$store.direct.commit.settings.setButtonState(0)
			return
		} else if (msg.action === 'cancel_launch') {
			this.show = false
		} else if (msg.action === 'migrate-init') {
			let iframe: HTMLIFrameElement = document.getElementById('vc-chat-iframe') as HTMLIFrameElement
			if (!iframe || !iframe.contentWindow) return

			iframe.contentWindow.postMessage({
				action: 'migrate-exchange',
				migration: this.$store.direct.state.settings.migration || {}
			}, process.env.VUE_APP_TRUSTED_WIDGET)
		} else if (msg.action === 'migrate-ok') {
			console.log('migrate ok!')
		} else if (msg.action === 'exchange-credential') {

			let iframe: HTMLIFrameElement = document.getElementById('vc-chat-iframe') as HTMLIFrameElement
			if (!iframe || !iframe.contentWindow) return

			console.log('--credential', msg.payload)
			let username = Storage.Get('username')
			if (username) { // use current username & password
				let password = Storage.Get('password')
				console.log('--credential', 'using old credential')
				if (msg.payload.return !== 1) return
				iframe.contentWindow.postMessage({
					action: 'exchange-credential-confirm',
					payload: {
						username,
						password,
						vcpid
					}
				}, process.env.VUE_APP_TRUSTED_WIDGET)

			} else { // save it
				console.log('--credential', 'saving credential')
				Storage.Set('username', msg.payload.username)
				Storage.Set('password', msg.payload.password)

				if (msg.payload.return !== 1) return
				iframe.contentWindow.postMessage({
					action: 'exchange-credential-confirm',
					payload: {
						...msg.payload,
						vcpid
					}
				}, process.env.VUE_APP_TRUSTED_WIDGET)
				
			}
		} else if (msg.action === 'ask-credential') {
			let iframe: HTMLIFrameElement = document.getElementById('vc-chat-iframe') as HTMLIFrameElement
			if (!iframe || !iframe.contentWindow) return

			let username = Storage.Get('username')
			let password = Storage.Get('password')

			const payload: {username?: string, password?: string, vcpid?: string | null} = {}

			if (username) {
				payload.username = username
				payload.password = password!
				payload.vcpid = vcpid
			} else {
				payload.vcpid = vcpid
			}

			iframe.contentWindow.postMessage({
				action: 'exchange-credential-confirm',
				payload,
			}, process.env.VUE_APP_TRUSTED_WIDGET)
		} else if (msg.action === 'vc-ask-dom-user-attribute') {
			let iframe: HTMLIFrameElement = document.getElementById('vc-chat-iframe') as HTMLIFrameElement
			if (!iframe || !iframe.contentWindow) return

			console.log('--sending DOMAttributes', DOMUserAttribute)

			iframe.contentWindow.postMessage({
				action: 'vc-dom-user-attribute',
				domAttributes: DOMUserAttribute
			}, process.env.VUE_APP_TRUSTED_WIDGET)
		} else if (msg.action === 'vc-event') {
			const payload: IEventPayload = msg.payload as IEventPayload
			SendEventToHandler(payload)
		}
	}

	async run() {
		console.log('app.run')
		await this.$store.direct.dispatch.settings.loadSettings()
		this.show = this.$store.direct.state.settings.setting!.whitelisted === true
		// await waitProm(500)

		let behaviour = isMobile() ? this.$store.direct.state.settings.setting!.mobileBehaviour : this.$store.direct.state.settings.setting!.behaviour
		let delay = isMobile() ? this.$store.direct.state.settings.setting!.mobileDelay : this.$store.direct.state.settings.setting!.delay
		let bkey = isMobile() ? 'bhvr_mob' : 'bhvr'
		let openImmediately = this.$store.direct.state.settings.setting!.openImmediately || false
		
		await waitProm(500)
		this.activatorPose = 'show'
		if (!openImmediately) {
			await waitProm(delay)
		} else {
			behaviour = 1
		}

		console.log('OK!')

		let bkeyval = localStorage.getItem(bkey)

		if (this.panelPose === 'show' && (behaviour === 1 || behaviour === 2) || (bkeyval === '1' && behaviour !== 1)) {
			return
		}

		if (behaviour === 1) {
			this.activatorClicked()
		} else if (behaviour === 2) {
			let behaviourTriggered = localStorage.getItem(bkey)
			if (!behaviourTriggered) {
				this.activatorClicked()
			}
		}
	}

	activatorClicked() {
		let bkey = isMobile() ? 'bhvr_mob' : 'bhvr'
		localStorage.setItem(bkey, '1')

		this.panelPose = this.panelPose === 'show' ? 'hide' : 'show'

		console.log('clicked. panelPose', this.panelPose)
		this.$store.direct.commit.settings.setButtonState( this.panelPose === 'show' ? 1 : 0 )

		SendEventToHandler({ 
			type: 'chatbot-interaction',
			data: {
				name: this.panelPose === 'show' ? 'open' : 'close',
				category: 'button'
			}
		})

		if (
			this.panelPose === 'show' && 
			this.$store.direct.state.settings.setting!.hideCloseButton === true
		) {
			this.activatorPose = 'hide2'
		} else if (this.activatorPose === 'hide2' || this.activatorPose === 'hide') {
			this.activatorPose = 'show'
		}
	}

}
