import 'core-js/stable'

import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import store from './store'
// import router from './router'

import './assets/main.css'
import 'vue-material-design-icons/styles.css';

// localStorage.setItem('ak', process.env.VUE_APP_API_KEY)

let appDom = document.getElementById('app')
let appId = '#app'
if (!appDom) {
	appDom = document.getElementById('vouch-chat')
	appId = '#vouch-chat'
}

if (!appDom) {
	throw new Error('app not found')
}

let apikey = appDom.getAttribute('apikey')
let widgetProfile = appDom.getAttribute('widget-profile')
// let merchant = document.getElementById('app')!.getAttribute('merchant')

console.log('apikey', apikey)
console.log('widgetProfile', widgetProfile)

localStorage.setItem('ak', apikey!)
if (widgetProfile) {
	localStorage.setItem('profile', widgetProfile!)
} else {
	localStorage.removeItem('profile')
}
// localStorage.setItem('mr', merchant!)

Vue.config.productionTip = false

declare global {
	interface Window {
		vouchApp:any
	}
}

window.vouchApp = new Vue({
	store: store.original,
	// router,
	render: h => h(App),
	methods: {
		toggle: function() {
			let app: any = this.$children[0]
			app.activatorClicked()
		}
	}
}).$mount(appId)
