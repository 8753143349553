
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Storage } from '../utils/util.storage'

@Component
export default class ChatPanel extends Vue {

	openCount = 0
	parentSearch?: URLSearchParams
	allowLoad = false
	isLoading = true

	@Prop({ default: false }) isopen!: boolean
	@Watch('isopen')
	isopenChanged() {
		console.log('isopen', this.isopen)
		if (this.openCount === 0) {
			// let state = this.$store.direct.state.settings.chatButtonState
			// this.$store.direct.commit.settings.setButtonState( state === 0 ? 1 : 0 )
		}
		if (this.isopen === true) this.openCount += 1

		if (this.openCount < 2) {
			let iframe: HTMLIFrameElement = document.getElementById('vc-chat-iframe') as HTMLIFrameElement
			if (!iframe || !iframe.contentWindow) return

			iframe.contentWindow.postMessage({
				action: 'panel_open'
			}, process.env.VUE_APP_TRUSTED_WIDGET)
		}
	}

	chatURL = process.env.VUE_APP_TRUSTED_WIDGET
	get chatframeURL(): string {
		console.log('chatpanel.chatframeURL')
		let migration = this.$store.direct.state.settings.migration
		let migrate = 0
		if (migration && migration.enabled === true) {
			migrate = 1
		}

		let channel = Storage.Get('channel')

		return `${ this.chatURL }?${ channel ? `channel=${ channel }&` : '' }${ migrate === 1 ? 'migrate=1&' : '' }ak=${ localStorage.getItem('ak') }&profile=${ localStorage.getItem('profile') ? localStorage.getItem('profile') : 'Default' }&fallbackURL=${ encodeURI(location.href) }#/?${ this.parentSearch ? this.parentSearch.toString() : '' }`
	}

	mounted() {
		console.log('chatpanel.mounted')
		let search = new URLSearchParams(location.search)
		let frontURL = window.location.href.split('?')[0]
		this.parentSearch = new URLSearchParams(location.search)

		search.delete('vcref')
		let url = frontURL
		let keys = Array.from(search.keys())
		console.log('chatpanel search keys', keys)
		if (keys.length > 0) {
			url = frontURL + '?' + search.toString()
		}
		console.log('chatpanel url', url)
		window.history.pushState({}, '', url)

		this.allowLoad = true
		// let url = 
		// window.history.pushState(null, null, )
	}

}

